import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Card } from 'reactstrap';
import { SHIPMENT_SUPPLIER, ORDER_TYPES, YES_NO, SERVICES_TYPE } from 'utils/constants';
import './ShipmentConfirmation.scss';
import dussledropImage from "../../../assets/images/deliveryPartner/dusseldrop-logo.png"

const keysToIncludeInAddress = ["Name", "Street", "PostalCode", "Place", "State", "Country"];


const ShipmentConfirmationForDusselDrop = (props, args) => {
    const { show, tittle, deliveryPartner, activeShipmentDetail = [], customerDetail = null, defaultValues = null, onConfirmClick, onCloseClick } = props;

    const [deliveryInfo, setDeliveryInformation] = useState({
        orderType: 'B2C',
        floor: 1,
        elevator: true,
        delivery_instruction: ''
    })

    const [packageInfo, setPackageInfo] = useState([])

    useEffect(() => {
        if (activeShipmentDetail?.length > 0) {
            const data = activeShipmentDetail?.map((product, i) => ({
                product_id: product?.product_id,
                productName: product?.item?.name, //  new added
                supplier: null,
                quantity: product?.quantity,
                sku:product?.item?.sku,
                packages: Array.from({ length: product?.item?.packages ? product?.item?.packages : 1 }, (v, i) => i + 1).map((item, i) => ({ 'Length': product?.item?.packages_dimensions[i]?.length?.toString() || '', 'Width': product?.item?.packages_dimensions[i]?.width?.toString() || '', 'Height': product?.item?.packages_dimensions[i]?.height?.toString() || '', 'Weight': product?.item?.packages_dimensions[i]?.weight?.toString() || '', 'AmountVpe': '1', 'Vpe': 'COL' }))
            }))
            setPackageInfo(data)
        }
    }, [activeShipmentDetail, show])

    const handleDeliveryInfo = (e) => {
        const { name, value } = e.target
        setDeliveryInformation(prev => ({ ...prev, [name]: value }))
    }

    const handlePackageInfo = (productId, packageIndex, newPackageData) => {
        setPackageInfo((prevProducts) =>
            prevProducts.map((product) =>
                product.product_id === productId
                    ? {
                        ...product,
                        packages: product.packages.map((pkg, index) =>
                            index === packageIndex ? { ...pkg, ...newPackageData } : pkg
                        ),
                    }
                    : product
            )
        );
    };

    const updateSupplier = (productId, newSupplier) => {
        setPackageInfo((prevProducts) =>
            prevProducts.map((product) =>
                product.product_id === productId ? { ...product, supplier: newSupplier } : product
            )
        );
    };

    const saveData = () => {
        const payload = {
          deliveryInfo: {
            ...deliveryInfo,
            floor: JSON.parse(deliveryInfo?.floor),
            elevator: deliveryInfo?.elevator
              ? JSON.parse(deliveryInfo?.elevator)
              : null,
          },
          package_info: packageInfo,
          deliveryPartner: deliveryPartner,
        }
        onConfirmClick(payload);
    }

    const closeModel = () => {
        setDeliveryInformation({
            orderType: 'B2C',
            floor: 0,
            elevator: null ,
            delivery_instruction: '',
            ServiceType : ' '
        })
        setPackageInfo([])
        onCloseClick();
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        saveData();
        closeModel();
    }

    const createAddressString = (index, keys) => {
        const supplierAddress = SHIPMENT_SUPPLIER.find(o => o.value === packageInfo[index]?.supplier)?.address
        return keys
            .map(key => supplierAddress?.Address[key])
            .filter(value => value) // Filter out null or undefined values
            .join(', ');
    }

    return (
        <div>
            <Modal className='shipment-confirmation' isOpen={show} toggle={() => closeModel()} centered={true} backdrop={true} size='xl' {...args}>
                <ModalHeader toggle={() => closeModel()} className='header sticky-top'><p className='delivery-partner mb-0'>{tittle}</p></ModalHeader>
                <ModalBody>
                <div className='d-flex align-items-start'>
                            <div className='div-details'>
                            <p className='delivery-partner'>Delivery Partner : {deliveryPartner}</p>
                            <img src={dussledropImage} alt='logique' className='jos-delivery-partner-image'/>
                            </div> 
                        <div className='customer-detail mt-2'>
                            <p className='fs-5 fw-bold'>Customer Information</p>
                            <p className='custom-p-spacing'>{customerDetail?.name}</p>
                            <p className='custom-p-spacing'>{customerDetail?.address}</p>
                            <p className='custom-p-spacing'>{`${customerDetail?.country}, ${customerDetail?.city}`}</p>
                            <p className='custom-p-spacing'>{customerDetail?.telephone}</p>
                            <p className='custom-p-spacing'>{customerDetail?.email?.split(':')[1]}</p>
                        </div>
                        </div>
                    <div>
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <Card className="main-card">
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <div>
                                            <p className='title mb-1'>Order Type <i className='error'>*</i> </p>
                                            <select name="orderType" className='form-select mb-2' onChange={(e) => handleDeliveryInfo(e)}>
                                                {ORDER_TYPES.map((item, i) => (
                                                    <option
                                                        key={i}
                                                        value={item.label}
                                                        selected={deliveryInfo?.orderType === item?.label}
                                                    >
                                                        {item.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        
                                      <div className="form-row">
                                        <div className="form-group">
                                            <p className='title mb-1'>Floor?</p>
                                            <input name="floor" type='number' className='form-control mb-2' min="0" onChange={(e) => handleDeliveryInfo(e)} ></input>
                                        </div>
                                        <div className="form-group">
                                            <p className='title mb-1'>Elevator?</p>
                                            <select name="elevator" className='form-select' onChange={(e) => handleDeliveryInfo(e)}>
                                                {YES_NO.map((item, i) => (
                                                    <option
                                                        key={i}
                                                        value={item.value}
                                                        selected={null}
                                                    >
                                                        {item.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                      </div>

                                      <div>
                                        <p className='title mb-1'> Services</p>
                                        <select name="service" className='form-select mb-2' onChange={(e) => handleDeliveryInfo(e)}>
                                                {SERVICES_TYPE.map((item, i) => (
                                                    <option
                                                        key={i}
                                                        value={item.label}
                                                        selected={deliveryInfo?.orderType === item?.label}
                                                    >
                                                        {item.label}
                                                    </option>
                                                ))}
                                            </select>
                                      </div>
                                    </div>

                                    <div className='col-md-8'>
                                        <p className='title mb-1'>Extra info / instructions for delivery </p>
                                        <textarea className='form-control box-details' name="delivery_instruction" value={deliveryInfo?.delivery_instruction} rows={9} cols={40} onChange={(e) => handleDeliveryInfo(e)}></textarea>
                                    </div>
                                </div>


                                {activeShipmentDetail?.map((product, i) => {
                                    return (
                                        <div className='product-section mt-3' key={i}>
                                            <div className='product-info d-flex align-items-center justify-content-between'>
                                                <div>
                                                    <p className='title text-uppercase'>Name</p>
                                                    <p className='fs-6 fw-normal'>{product?.item?.name}</p>
                                                </div>
                                                <div>
                                                    <p className='title text-uppercase'>Quantity</p>
                                                    <p className='text-center'>{product?.quantity}</p>
                                                </div>
                                                <div>
                                                    <p className='title text-uppercase'>Packages</p>
                                                    <p className='text-center'>{product?.item?.packages ? product?.item?.packages : 1}</p>
                                                </div>
                                            </div>
                                            <div className='package-info mt-3'>
                                                <div className='package-header'>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                        </div>
                                                        <div className='col-md-8 d-flex align-items-center'>
                                                            <span className='title title-width'>LENGTH(CM)<i className='error'>*</i></span>
                                                            <span className='title ms-3 title-width'>WIDTH(CM)<i className='error'>*</i></span>
                                                            <span className='title ms-3 title-width'>HEIGHT(CM)<i className='error'>*</i></span>
                                                            <span className='title ms-3 title-width'>WEIGHT(KG)<i className='error'>*</i></span>
                                                            <span className='title ms-3 title-width'>AMOUNT VPE<i className='error'>*</i></span>
                                                            <span className='title ms-3 title-width'>VPE<i className='error'>*</i></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* {Array.from({ length: total_package }, (v, i) => i + 1).map((item, package_index) => { */}
                                                {packageInfo?.length > 0 && packageInfo[i]?.packages?.map((pkg, package_index) => {
                                                    return (
                                                        <div key={package_index} className='row mt-2'>
                                                            <div className='col-md-4'>
                                                                <p>Package {package_index + 1}</p>
                                                            </div>
                                                            <div className='col-md-8 d-flex align-items-center'>
                                                                <input name={`length_${package_index}`} type="number" value={pkg.Length} className='form-control element-width'  onChange={(e) => handlePackageInfo(product.product_id, package_index, { "Length": e.target.value })} required min="1" step="any"/>
                                                                <input name={`width_${package_index}`} type="number" value={pkg.Width} className='form-control ms-3 element-width'  onChange={(e) => handlePackageInfo(product.product_id, package_index, { "Width": e.target.value })} required min="1" step="any"/>
                                                                <input name={`height_${package_index}`} type="number" value={pkg.Height} className='form-control ms-3 element-width'  onChange={(e) => handlePackageInfo(product.product_id, package_index, { "Height": e.target.value })} required min="1" step="any"/>
                                                                <input name={`weight_${package_index}`} type="number" value={pkg.Weight} className='form-control ms-3 element-width'  onChange={(e) => handlePackageInfo(product.product_id, package_index, { "Weight": e.target.value })} required min="1" step="any"/>
                                                                <input name={`amount_vpe_${package_index}`} type="number" value={pkg.AmountVpe} defaultValue={1} className='form-control ms-3 element-width'  onChange={(e) => handlePackageInfo(product.product_id, package_index, { "AmountVpe": e.target.value })} required min="0"/>
                                                                <input name={`vpe_${package_index}`} type="text" value={pkg.Vpe} className='form-control ms-3 element-width'  onChange={(e) => handlePackageInfo(product.product_id, package_index, { "Vpe": e.target.value })} required />
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>

                                            <div className='row supplier-info mt-3'>
                                                <div className='col-md-4'>
                                                    <p className='title mb-1'>Supplier</p>
                                                    <select required className='form-select mb-2' value={packageInfo[i]?.supplier} onChange={(e) => updateSupplier(product.product_id, e.target.value)}>
                                                        {SHIPMENT_SUPPLIER.map((item, i) => (
                                                            <option
                                                                key={i}
                                                                value={item.value}
                                                            >
                                                                {item.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className='col-md-8'>
                                                    {/* <p className='title mt-4 mb-0'>{SHIPMENT_SUPPLIER.find(o => o.value === packageInfo[i]?.supplier)?.address}</p> */}
                                                    <p className='title mt-4 mb-0'>{createAddressString(i, keysToIncludeInAddress)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                            </Card>
                            <ModalFooter>
                                <Button color="secondary" onClick={() => closeModel()}>
                                    Cancel
                                </Button>{' '}
                                <Button type='submit' color="primary">
                                    Confim
                                </Button>
                            </ModalFooter>
                        </form>
                    </div>
                </ModalBody >
            </Modal>
        </div>
    );
}

export default ShipmentConfirmationForDusselDrop;