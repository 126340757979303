export function generateOrderId(length) {
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
  var orderId = ""

  for (var i = 0; i < length; i++) {
    var randomIndex = Math.floor(Math.random() * characters.length)
    orderId += characters.charAt(randomIndex)
  }
  return orderId
}

export const generateMonthNames = () => {
  const monthNames = []
  const currentDate = new Date()
  for (let month = 0; month < 12; month++) {
    currentDate.setMonth(month)
    const monthName = currentDate.toLocaleString("default", { month: "long" })
    monthNames.push(monthName)
  }
  return monthNames
}

export const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based in JS
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};