export const SUPPLIERS = [
  'STARFURN',
  'TOWERLIVING',
  'HOME67',
  'LABEL51',
  'BENOA',
  'DUTCHBONE',
  'BY_BOO',
  'LIVINGFURN',
  'PROFURN',
  'NIJWIE',
  'UNIQUE_FURNITURE',
  'KICK_COLLECTION',
  'ELEONORA',
  'PTMD'
]
export const STATUS = ['PENDING', 'APPROVED', 'REJECTED']
export const ORDER_STATUS = ['all', 'pending', 'processing', 'completed', 'on-hold', 'cancelled', 'refunded', 'partially-refunde', 'failed', 'trash', 'backorder']

export const PARTNERS = [
  { value: 1, label: "---" },
  { value: 68, label: "DPD" },
  { value: 6, label: "PostNL" },
  { value: 10, label: "Logique" },
  { value: 11, label: "Jos Dusseldorp" },
]

/*  Pick up information for Logique & Dussel Drop  */
const PICKUP = {
  "Connectivity": {
    "Email": "dummy@gmail.com",
    "Fax": null,
    "Mobile": null,
    "Phone": null,
    "Web": null,
  },
  "Requested": {
    "DateTimeBegin": "2020-01-04T09:00:00",
    "DateTimeEnd": "2020-01-04T17:00:00",
  },
  "ContactName": null,
  "Instructions": "Ophaalinstructies",
}

const PICKUP_ADDRESS = {
  "Address": {
    "Name": "Royal Palace Amsterdam",
    "Premise": null,
    "Street": "Dam Square 1",
    "Number": "2134565434",
    "PostalCode": "1012 JS",
    "Place": "Royal Palace Amsterdam",
    "State": "Amsterdam",
    "Country": "Nederland",
    "CountryCode": "NL",
    "CountryCodeCompact": "NL"
  }
}

export const SHIPMENT_SUPPLIER_DUMMY = [
  { label: "Select Supplier", value: '' },
  { label: 'Starfun', value: 'starfun', address: PICKUP_ADDRESS, PickUp: { ...PICKUP_ADDRESS, ...PICKUP } },
  { label: 'ByBoo', value: 'byBoo', address: PICKUP_ADDRESS, PickUp: { ...PICKUP_ADDRESS, ...PICKUP } },
  { label: 'Eleonora', value: 'eleonora', address: PICKUP_ADDRESS, PickUp: { ...PICKUP_ADDRESS, ...PICKUP } },
]


export const SHIPMENT_SUPPLIER_DETAILS = [
  {
    label: 'Stafurn',
    value: 'Stafurn B.V',
    address: {
      "Address": {
        "Name": "",
        "Premise": null,
        "Street": "Oeveraseweg",
        "Number": "2",
        "PostalCode": "7971 PA",
        "Place": "Havelte",
        "State": "",
        "Country": "Nederland",
        "CountryCode": "NL",
        "CountryCodeCompact": "NL"
      }
    },
    otherInfo: {
      "Connectivity": {
        "Email": "Inkoop@Cavetown.Ni",
        "Fax": null,
        "Mobile": '0652307159',
        "Phone": '0652307159',
        "Web": null,
      },
      "Requested": {
        "DateTimeBegin": "2020-01-04T09:00:00",
        "DateTimeEnd": "2020-01-04T17:00:00",
      },
      "ContactName": 'Anne Eefting',
      "Instructions": "",
    },
  },
  {
    label: 'ByBoo',
    value: 'By Boo',
    address: {
      "Address": {
        "Name": "",
        "Premise": null,
        "Street": "Koningsbergenweg",
        "Number": "2",
        "PostalCode": "3771 NS",
        "Place": "Barneveld",
        "State": "",
        "Country": "Nederland",
        "CountryCode": "NL",
        "CountryCodeCompact": "NL"
      }
    },
    otherInfo: {
      "Connectivity": {
        "Email": "Noreply@Cavetown.NI",
        "Fax": null,
        "Mobile": '0652307159',
        "Phone": '+31 342 740007',
        "Web": null,
      },
      "Requested": {
        "DateTimeBegin": "2020-01-04T09:00:00",
        "DateTimeEnd": "2020-01-04T17:00:00",
      },
      "ContactName": '',
      "Instructions": "",
    }
  },
  {
    label: 'Eleonora',
    value: 'Eleonora',
    address: {
      "Address": {
        "Name": "",
        "Premise": null,
        "Street": "Koningsbergenweg",
        "Number": "2",
        "PostalCode": "3771 NS",
        "Place": "Barneveld",
        "State": "",
        "Country": "Nederland",
        "CountryCode": "NL",
        "CountryCodeCompact": "NL"
      }
    },
    otherInfo: {
      "Connectivity": {
        "Email": "Noreply@Cavetown.NI",
        "Fax": null,
        "Mobile": '0652307159',
        "Phone": '+31 342 740007',
        "Web": null,
      },
      "Requested": {
        "DateTimeBegin": "2020-01-04T09:00:00",
        "DateTimeEnd": "2020-01-04T17:00:00",
      },
      "ContactName": '',
      "Instructions": "",
    }
  },
  {
    label: 'Benoa',
    value: 'Benoa',
    address: {
      "Address": {
        "Name": "",
        "Premise": null,
        "Street": "Engelseweg",
        "Number": "149",
        "PostalCode": "5705 AD",
        "Place": "Helmond",
        "State": "",
        "Country": "Nederland",
        "CountryCode": "NL",
        "CountryCodeCompact": "NL"
      }
    },
    otherInfo: {
      "Connectivity": {
        "Email": "Inkoop@Cavetown.NI",
        "Fax": null,
        "Mobile": '0492-751549',
        "Phone": '0492-751549',
        "Web": null,
      },
      "Requested": {
        "DateTimeBegin": "2020-01-04T09:00:00",
        "DateTimeEnd": "2020-01-04T17:00:00",
      },
      "ContactName": 'Maaike/Susan',
      "Instructions": "",
    }
  },
  {
    label: 'Cavetown B.V',
    value: 'Cavetown B.V',
    address: {
      "Address": {
        "Name": "",
        "Premise": null,
        "Street": "Montageweg",
        "Number": "11",
        "PostalCode": "3433 NT",
        "Place": "Nieuwegein",
        "State": "",
        "Country": "Nederland",
        "CountryCode": "NL",
        "CountryCodeCompact": "NL"
      }
    },
    otherInfo: {
      "Connectivity": {
        "Email": "Info@Cavetown.Ni",
        "Fax": null,
        "Mobile": '0642700111',
        "Phone": '085-0470391',
        "Web": null,
      },
      "Requested": {
        "DateTimeBegin": "2020-01-04T09:00:00",
        "DateTimeEnd": "2020-01-04T17:00:00",
      },
      "ContactName": 'Cavetown B.V.',
      "Instructions": "",
    },
  },
  {
    label: 'Livingturn B.V',
    value: 'Livingturn B.V',
    address: {
      "Address": {
        "Name": "",
        "Premise": null,
        "Street": "Industrieweg",
        "Number": "2",
        "PostalCode": "6662 PA",
        "Place": "Elst",
        "State": "",
        "Country": "Nederland",
        "CountryCode": "NL",
        "CountryCodeCompact": "NL"
      }
    },
    otherInfo: {
      "Connectivity": {
        "Email": "Inkoop@Cavetown.Ni",
        "Fax": null,
        "Mobile": '0481 350 659',
        "Phone": '0481 350 659',
        "Web": null,
      },
      "Requested": {
        "DateTimeBegin": "2020-01-04T09:00:00",
        "DateTimeEnd": "2020-01-04T17:00:00",
      },
      "ContactName": 'Serge',
      "Instructions": "",
    },
  },
  {
    label: 'Nijwie',
    value: 'Nijwie',
    address: {
      "Address": {
        "Name": "",
        "Premise": null,
        "Street": "Het Wendeigcor",
        "Number": "5",
        "PostalCode": "7604 PJ",
        "Place": "Almelo",
        "State": "",
        "Country": "Nederland",
        "CountryCode": "NL",
        "CountryCodeCompact": "NL"
      }
    },
    otherInfo: {
      "Connectivity": {
        "Email": "Orders@Nijwie NI",
        "Fax": null,
        "Mobile": '0546564001',
        "Phone": '0546564001',
        "Web": null,
      },
      "Requested": {
        "DateTimeBegin": "2020-01-04T09:00:00",
        "DateTimeEnd": "2020-01-04T17:00:00",
      },
      "ContactName": 'Andre Idema',
      "Instructions": "",
    },
  },
]

export const SHIPMENT_SUPPLIER = [{ label: "Select Supplier", value: '' }]

export const ORDER_TYPES = [
  { value: 1, label: "B2C" },
  { value: 2, label: "B2C Retour" },
]

export const YES_NO = [
  { label: null ,value: " "},
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]

export const SERVICES_TYPE =[
  {value: 1, label: "Select Service"},
  {value: 2, label: "Basic 1"},
  {value: 3, label: "Basic 2"},
  {value: 4, label: "Advanced 1"},
  {value: 5, label: "Advanced 2"},
  {value: 6, label: "Deluxe 1"},
  {value: 7, label: "Deluxe 2"}
]

SHIPMENT_SUPPLIER_DETAILS?.map((item, index) => {
  SHIPMENT_SUPPLIER.push({
    label: item?.label,
    value: item?.value,
    address: item.address,
    PickUp: { ...item.address, ...item.otherInfo }
  })
})
